<template>
  <!-- Messages Dropdown Menu -->

  <li class="nav-item dropdown userinfo">
    <a class="nav-link" data-toggle="dropdown" href="#">
      <div class="image">
        <i class="fas fa-users-cog"></i>
      </div>
      <div class="info">{{ title }}</div>
    </a>
    <div class="min-width-auto dropdown-menu dropdown-menu-lg dropdown-menu-right">
      <div class="dropdown-divider"></div>
      <template v-for="item in items" :key="item.id">
        <Item :item="item"></Item>
      </template>
      <div class="dropdown-divider"></div>
      <a href="javascript:void(0);" class="dropdown-item" @click="logout">
        <i class="fas fa-sign-out-alt mr-2"></i> 登出
      </a>
    </div>
  </li>
</template>

<script>
import Item from "./Userinfo-Item.vue"
export default {
  props: ['widget'],
  components: {
    Item
  },
  data() {
    // 取得元件代碼
    let code = this.widget.widget;

    // 取得元件資訊
    let current_widget_meta = this.$microlink.widgets[code];

    // 取得 userinfo api url
    let userinfo_api_url = current_widget_meta.widget.properties.events.userinfo_get;

    // 透過 ajax 取得目前的登入者資訊
    let get_userinfo_event = this.$microlink.base_api_url + userinfo_api_url;
    let response = this.$microlink.api_get(get_userinfo_event);

    let title = (response == undefined || response.title == undefined) ? '' : response.title;
    let items = current_widget_meta.widget.properties.items;

    if (title != '') {
      // 透過 regular expression 取得 link 中{}的變數，並取代成實際的值
      for (var key in items) {
        let link = items[key].link;

        let matches = link.match(/\{[a-zA-Z0-9_]+\}/g);

        if (matches) {
          for (var i = 0; i < matches.length; i++) {
            let variable = matches[i].replace('{', '').replace('}', '');
            link = link.replace(matches[i], response[variable]);
          }
        }

        items[key].link = link;
      }

      this.$microlink.userinfo = response;
    }
    else {
      items = [];
      this.$microlink.userinfo = {};
    }
    return {
      items: items,
      title: title
    }
  },
  methods: {
    logout() {
      let logout_event = this.$microlink.base_api_url + '/user/logout';
      let response = this.$microlink.api_get(logout_event);

      if (response) {
        localStorage.removeItem('remember');
        window.location.reload();
      }
    }
  }
}
</script>

<style scoped>
li.userinfo div.image {
  width: 35px;
  font-size: 20px;
  padding-left: 5px;
  display: inline-block;
  margin-right: 5px;
  border: 1px solid gray;
  border-radius: 10px;
  color: white;
  background: gray;
}

li.userinfo a.nav-link {
  padding-top: 5px;
}

li.userinfo img {
  width: 100%;
}

li.userinfo div.info {
  display: inline-block;
}

.min-width-auto {
  min-width: auto !important;
}
</style>