<template>
  <a v-bind:href="data.link" v-bind:target="is_link ? '_blank': '_self'" class="dropdown-item">
    <i v-bind:class="[data.icon]" class="mr-2"></i> {{data.title}}
  </a>
</template>

<script>
export default {
  props: ['item'],
  data(){

    let localItem = this.item;
    let is_link = true;
    if(localItem.link_type == 'page'){

      // 將網址的 # 之後的字串移除
      // 例如: http://localhost:8080/#/dashboard
      // 移除後: http://localhost:8080/

      let url = location.href;
      let matches = url.match(/#\/.*/g);
      if(matches){
        url = url.replace(matches[0], '');
      }

      // 將網址的 # 之前的字串移除
      // 例如: http://localhost:8080/#/dashboard
      // 移除後: /dashboard

      let matches2 = localItem.link.match(/#\/.*/g);
      if(matches2){
        localItem.link = matches2[0].replace('#', '');
      }

      localItem.link = url + '#' + localItem.link;
      is_link = false;
    }

    return {
      data: localItem,
      is_link: is_link
    }
  }
}
</script>

<style>

</style>